<template>
  <div id="upload-modal" class="uk-flex-top"
    uk-modal="bg-close: false; esc-close: false"
  >
    <div class="uk-modal-dialog uk-margin-auto-vertical" uk-overflow-auto>
        <div class="uk-modal-header">
            <h3 class="uk-modal-title uk-text-left"> {{$t('Pages.MediaLibrary.UploadMedia') }} </h3>
        </div>
        <div class="uk-modal-body">
        <h5 class="uk-text-left uk-text-uppercase uk-margin-remove-bottom"> {{$t('Pages.MediaLibrary.PageUpload.HostedFiles') }} </h5>
        <p class="uk-text-meta uk-text-left uk-margin-remove-top"> {{$t('Pages.MediaLibrary.PageUpload.RecommendedFormats') }} </p>
        <InputUploader @start="uploading = true" @complete="onUploadComplete" @cancel="onCancel"/>
        <h5 class="uk-text-left uk-text-uppercase uk-margin-remove-bottom"> {{$t('Pages.MediaLibrary.PageUpload.ExternalLinks') }} </h5>
        <p class="uk-text-meta uk-text-left uk-margin-remove-top"> {{$t('Pages.MediaLibrary.PageUpload.RecommendedExternalLinksFormat') }} </p>
        <div  v-for="( linkInfo, index ) in externalLinks" :key="`external-link:${index}`"  class="uk-grid-small uk-grid uk-margin-remove-left">
            <div class="uk-inline uk-width-expand uk-padding-remove-left">
                <span class="uk-form-icon uk-form-icon-flip uk-margin-small-right tooltip  inline-error-bg" v-if="!focused.url && !validateUrl(linkInfo.url)&&linkInfo.url.length>1">
                    {{ $t('Pages.MediaLibrary.InvalidURL') }}
                    <span class="uk-margin-small-left invalid-icon" uk-icon="icon: warning"></span>
                </span>
                <input class="uk-input"
                    :class="{ 'invalid-border': !focused.url && !validateUrl(linkInfo.url) && linkInfo.url.length > 1 }"
                    type="url" 
                    v-model="linkInfo.url"
                    @input="validateUrl($event.target.value)"
                    @focus="focused.url = true"
                    @blur="focused.url = false"
                    :placeholder="$t('Pages.MediaLibrary.URLPlaceholder')" />
            </div>
            <label class="uk-width-auto uk-margin-small-top"><input class="uk-checkbox" type="checkbox" v-model="linkInfo.live"> {{$t('Pages.MediaLibrary.PageUpload.LiveEvent') }}  </label>
            <span v-if="externalLinks.length>1" class="uk-width-auto uk-margin-small-top" style="cursor: pointer;" uk-icon="icon: close" @click="deleteExternalLink(index)" ></span>
            <div class="uk-flex uk-width-1-1 uk-padding-remove-left">
                <InputDatePicker
                    v-if="linkInfo.live"
                    v-model="linkInfo.liveStartTime"
                    class="uk-width-1-2 uk-margin-small-right"
                    :label="$t('Pages.MediaLibrary.Labels.StartTime')"
                    enable-time
                    handle-utc
                    :min-date="minDate"
                    :placeholder="$t('Pages.MediaLibrary.PageUpload.StartPlaceholder')"
                    required
                />
                <InputDatePicker
                    v-if="linkInfo.live"
                    v-model="linkInfo.liveEndTime"
                    class="uk-width-1-2"
                    :label="$t('Pages.MediaLibrary.Labels.EndTime')"
                    enable-time
                    handle-utc
                    :min-date="minEndDate(linkInfo)"
                    :placeholder="$t('Pages.MediaLibrary.PageUpload.EndPlaceholder')"
                    no-button-now
                    required
                    date-picker-position
                />
            </div>
            <span class="uk-width-1-1 uk-form-danger uk-text-left uk-padding-remove" v-if="linkInfo.live && ( linkInfo.liveStartTime !== '' && linkInfo.liveEndTime !== '' ) && (linkInfo.liveStartTime >= linkInfo.liveEndTime) "> {{$t('Pages.MediaLibrary.PageUpload.DateValidation')}}
                <span class="invalid-icon" uk-icon="icon: warning" ></span>
            </span>
        </div>
        <button v-if="canAddAnotherExternalLink" :disabled="!validateExternalLinks" class="uk-button uk-button-primary uk-margin-small-top uk-margin-small-bottom uk-align-left" type="button" @click="addExternalLink"> {{$t('Pages.MediaLibrary.PageUpload.AddExternalLink')}} </button>
        </div>
        <div class="uk-modal-footer uk-text-right uk-margin-small-top uk-width-1-1">
            <span v-if="errors.noMedia" class="uk-text-danger uk-margin-small-bottom">{{$t('Pages.MediaLibrary.PageUpload.MediaOrLinkRequired')}} <span class="invalid-icon" uk-icon="icon: warning" ></span></span>
            <br>
            <button class="uk-button uk-button-default uk-margin-small-right uk-margin-small-top uk-modal-close" type="button" @click="clearData"> {{ $t('Actions.Cancel') }} </button>
            <button v-if="!submit" class="uk-button uk-button-primary uk-margin-small-top" type="button" :disabled="uploading || !validateExternalLinks" @click.prevent="upload" > {{ $t('Actions.Upload') }}  </button>
            <button v-else class="uk-button uk-button-primary uk-margin-small-top" type="button" :disabled="true"  > <span uk-spinner="ratio: 1"> </span> </button>
        </div>
    </div>
  </div>     
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { MediaClass } from '../../utils/enums';
import InputUploader from '@/views/admin/mediaLibrary/InputUploader';
import InputDatePicker, { dateFormat } from '../InputDatePicker';
import moment from 'moment';
import Notification from '@/components/util/Notification';

const validUrlPattern = /^(http(s)?:\/\/){1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/; // eslint-disable-line
const getQueryMediaTypeFilter = selectedMediaClassFilter => (
    selectedMediaClassFilter !== 'All'
        ? [ selectedMediaClassFilter === MediaClass.VIDEO || selectedMediaClassFilter === MediaClass.LIVE ? 'MediaBundle' : 'Media' ]
        : [ 'MediaBundle', 'Media' ]);

export default {
    components: {
        InputUploader,
        InputDatePicker
    },
    props: {
        mediaClass: { type: String }
    },
    data () {
        return {
            externalLinks: [ { url: '', live: false, liveStartTime: '', liveEndTime: '' } ],
            files: [],
            uploading: false,
            submit: false,
            MediaClass,
            focused: {
                url: false
            },
            errors: {
                files: false,
                externalLinks: false,
                noMedia: false
            }
        }
    },
    watch: {
        canAddAnotherExternalLink () {
            if (this.canAddAnotherExternalLink) {
                this.errors.noMedia = false
            }
        }
    },
    computed: {
        ...mapState({
        rootFolderId: state => state.venom.org.mediaLibraryRootFolderID
        }),
        ...mapGetters([
            'orgId'
        ]),
        canAddAnotherExternalLink () { return !!this.externalLinks[this.externalLinks.length - 1]?.url.trim(); },
        minDate () {
            return moment().format( dateFormat );
        },
        hasAtLeastOneUploaded () {
            return this.files.length > 0 || (
                this.externalLinks.length > 0 && this.externalLinks.find( link => !!link.url.trim() )
            );
        },
        validateExternalLinks () {
            for ( const { url, live, liveStartTime, liveEndTime } of this.externalLinks.filter( l => !!l.url.trim() ) ) {
                const startType = typeof liveStartTime;
                const endType = typeof liveEndTime;
                if( !this.validateUrl(url) ) 
                    return false;
                else if ( live && ( liveStartTime === '' && liveEndTime === '' ) )
                    return false;
                else if ( live && ( startType !== 'string' && endType !== 'string' ) )
                    return false;
                else if ( live && liveStartTime >= liveEndTime )
                    return false;
            }
            return true;
        }
    },
    methods: {
        addExternalLink () {
            this.externalLinks.push({ url: '', live: false });
        },
        validateUrl ( url ) {
            return validUrlPattern.test( url );
        },
        validateUpload () {
            let isValid = true
            if (!this.files.length && !this.externalLinks[this.externalLinks.length - 1]?.url.trim()) {
                this.errors.noMedia = true
                isValid = false
            } else if (!this.validateExternalLinks) {
                isValid = false
            }
            return isValid
        },
        async upload () {
            if (!this.validateUpload()) return false
            this.submit = true;
            // check if upload is in sub-folders
            let folder;
            if(this.$route.params.path) {
                folder = this.$route.params.path.split( '/' ).pop().trim();
            }
            if(!folder)
                folder = this.rootFolderId
            
            // Create Media for external links
            try {
                for ( const { url, live, liveStartTime, liveEndTime } of this.externalLinks.filter( l => !!l.url.trim() ) ) {
                    const variables = { external: url, folder: folder, mediaClass: live ? MediaClass.LIVE : MediaClass.VIDEO, 
                    liveStartTime: live && liveStartTime !== '' ? liveStartTime : null,
                    liveEndTime: live  && liveEndTime !== '' ? liveEndTime : null };
                    const { id, mediaClass } = await this.$store.dispatch('createMedia',  variables );
                    if ( id && mediaClass === MediaClass.VIDEO || mediaClass === MediaClass.LIVE ) {
                        await this.$store.dispatch('createMediaBundle', { mediaId: id });
                    }
                }

                //Create Media for internal files
                for ( const { path, mediaClass, file } of this.files ) {
                    const variables = { uploaded: { key: path }, folder: folder, mediaClass, title: file.name };
                    const media = await this.$store.dispatch('createMedia', variables );
                    if ( media.id && media.mediaClass === MediaClass.VIDEO ) {
                        await this.$store.dispatch('createMediaBundle', { mediaId: media.id });
                    }
                }
                
                window.UIkit.modal('#upload-modal').hide();
                Notification.showNotification(this.$t('Generic.Messages.Successful'), this.$t('Pages.MediaLibrary.MediaUploadSuccessful') );
                this.submit = false;
                this.clearData();
                const filter = this.mediaClass ? true : false;
                const type = filter ? getQueryMediaTypeFilter(this.mediaClass) : this.type;
                if( !this.$route.params.path ) {
                    await this.$store.dispatch('fetchMediaLibraryItems', { orgId: this.orgId, filter: filter, type: type, mediaClass: this.mediaClass ? this.mediaClass : null});
                } else {
                    await this.$store.dispatch('fetchMediaLibraryFolderItems',{ orgId: this.orgId, folderId: folder, filter: filter, type: type, mediaClass: this.mediaClass ? this.mediaClass : null })  
                }
            } catch ( err ) {
                this.submit = false;
                Notification.showNotification(this.$t('Generic.Messages.UnSuccessful'), this.$t('Pages.MediaLibrary.UploadFailed'), 'error');
            }
        },
        onUploadComplete ( files ) {
            this.uploading = false;
            this.files = files;
            this.errors.noMedia = false
        },
        onCancel () {
            if ( !this.files.length ) {
                this.uploading = false;
            }
        },
        deleteExternalLink ( index ) {
            this.externalLinks.splice(index,1);
        },
        minEndDate ({ liveStartTime }) {
            const dateType = typeof liveStartTime;
            if ( liveStartTime && dateType === "string" ) {
                const date = liveStartTime && new Date( liveStartTime );
                return moment( date?.setMinutes( date.getMinutes() + 1 ) ).format( dateFormat );
            }
            return moment().format( dateFormat );
        },
        clearData () {
            this.errors = {
                files: false,
                externalLinks: false,
                noMedia: false
            }
            this.externalLinks = [ { url: '', live: false, liveStartTime: '', liveEndTime: '' } ],
            this.files = [];
            this.$children[0].startedFiles = [];
            this.$children[0].seenFileNames = {};
            this.$children[0].cancelledFiles = [];
            this.$children[0].queuedFiles = [];
            this.$children[0].allFiles = [];
        }
    }
}
</script>

<style lang="scss" scoped>
.tooltip {
    color: #e69393;
    width: auto;
}
</style>