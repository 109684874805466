<template>
  <div>
    <div class="uk-flex uk-width-1-1 uk-flex-between">
      <h1 v-if="!path" class="uk-text-left uk-margin-remove ">{{ $t('Sidebar.MediaLibrary') }}</h1>
      <div v-else class="uk-flex uk-text-left uk-width-2-5@xl uk-width-1-4">
        <h1 class="uk-text-left uk-margin-remove uk-width-auto uk-text-truncate"><a @click="$router.go(-1)" style="text-decoration: none;" class="uk-flex uk-flex-middle"><span style="min-width:44px" uk-icon="icon: chevron-left; ratio: 2.2"></span><span class="uk-text-truncate" :uk-tooltip="folderName">{{folderName}}</span></a></h1>
        <span id='folder-edit-icon' style="cursor: pointer; margin-top: 12px; min-width: 30px; color: var(--app-primary-color);" class="uk-margin-small-left" uk-icon="icon: pencil; ratio: 1.5" @click="editFolderName"> </span>
      </div>
      <div class="uk-align-right uk-margin-remove-bottom">

        <button class="uk-button uk-button-primary new-folder-btn uk-width-auto uk-margin-small-right uk-align-right uk-margin-remove" href="#upload-modal" uk-toggle> 
          <span class="uk-margin-small-right" uk-icon="cloud-upload"></span> {{ $t('Actions.Upload') }} </button>
        <button class="uk-button uk-button-primary uk-margin-small-right new-folder-btn uk-width-auto uk-align-right uk-margin-remove-bottom uk-margin-remove-left" type="button" @click="openFolderModal">
          <span class="uk-margin-small-right" uk-icon="plus-circle"></span> {{$t('Pages.MediaLibrary.NewFolder') }}
        </button>

        <div class="uk-margin-small-bottom uk-align-right uk-margin-remove uk-margin-small-left">
          <button v-if="view === 'table'" :uk-tooltip="$t('Pages.MediaLibrary.TileView')" aria-label="Tile View" style="padding-left:10px; padding-right:10px" class="uk-margin-small-left uk-button uk-button-primary uk-margin-small-right" title="Tile View" type="button"> <i uk-icon="icon: grid" @click="changeDisplay('tile')"></i> </button>
          <button v-else :uk-tooltip="$t('Pages.MediaLibrary.ListView')" aria-label="List View" style="padding-left:10px; padding-right:10px" class="uk-margin-small-left uk-button uk-button-primary uk-margin-small-right" title="Table View" type="button" @click="changeDisplay('table')"> <i uk-icon="icon: table"></i> </button>
          <button :uk-tooltip="$t('Generic.Labels.Filter')" :aria-label="$t('Generic.Labels.Filter')" style="padding-left:10px; padding-right:10px; width:42px" class="uk-button uk-button-primary uk-margin-small-right" type="button"><img src="@/assets/filter-icon.svg" uk-svg /> </button>
          <div style="min-width: 150px;" uk-dropdown="mode: hover; pos: bottom-left; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 200">
            <ul class="uk-nav uk-dropdown-nav">
              <li :class="{'uk-active uk-text-bold': mediaClassFilter === 'All'}" class="uk-text-left uk-text-default" > <a @click="filterMediaItems('All')"> {{ $t( 'Generic.Labels.All' ) }} </a> </li>
              <li :class="{'uk-active uk-text-bold': mediaClass === mediaClassFilter}" class="uk-text-left uk-text-default" v-for="mediaClass in MediaClass.values" :key="mediaClass" :value="mediaClass" > <a @click="filterMediaItems(mediaClass)"> {{ $t( `enums.MediaClass.${mediaClass}` ) }} </a> </li>
            </ul>
          </div>
          <button :uk-tooltip="$t('Generic.Labels.Sort')" :aria-label="$t('Generic.Labels.Sort')" style="padding-left:10px; padding-right:10px" class="uk-button uk-button-primary uk-margin-small-right" type="button"><span uk-icon=code class="rotate-90"></span> </button>
          <div class="uk-padding-small" style="min-width: 110px;" uk-dropdown="mode: hover; pos: bottom-left; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 200">
            <ul class="uk-nav uk-dropdown-nav">
              <li class="uk-text-left uk-text-default" > <a @click="sortData('Date')"> {{$t('Pages.Analytics.Table.Date')}} <span v-if="sortDirection === 'asc' && sort === 'Date'" uk-icon="triangle-up"></span> <span v-if="sortDirection === 'desc' && sort === 'Date'" uk-icon="triangle-down" > </span> </a> </li>
              <li class="uk-text-left uk-text-default" > <a @click="sortData('Name')"> {{$t('Pages.Analytics.Table.Name')}} <span v-if="sortDirection === 'asc' && sort === 'Name'" uk-icon="triangle-up"></span> <span v-if="sortDirection === 'desc' && sort === 'Name'" uk-icon="triangle-down" > </span>  </a> </li>
              <li class="uk-text-left uk-text-default" > <a @click="sortData('Type')"> {{$t('Generic.Labels.Type')}} <span v-if="sortDirection === 'asc' && sort === 'Type'" uk-icon="triangle-up"></span> <span v-if="sortDirection === 'desc' && sort === 'Type'" uk-icon="triangle-down" > </span>  </a> </li>
              <li class="uk-text-left uk-text-default" > <a @click="sortData('Size')"> {{$t('Pages.MediaLibrary.Labels.Size')}} <span v-if="sortDirection === 'asc' && sort === 'Size'" uk-icon="triangle-up"></span> <span v-if="sortDirection === 'desc' && sort === 'Size'" uk-icon="triangle-down" > </span>  </a> </li>
            </ul>
          </div>
        </div>
        <div class="uk-search uk-width-auto@xl uk-width-1-3@l uk-search-default uk-align-right uk-margin-remove">
          <span uk-search-icon></span>
          <a v-if="searchInput" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: close" @click.prevent="clearSearch"></a>
          <input ref="searchInput" class="uk-search-input uk-width-medium" type="search" v-model.trim="searchInput" @input="processChange()" @blur="onBlur" :placeholder="$t('Generic.Labels.Search')">
        </div>
      </div>
    </div>
    <hr />
    <span v-if="spinner" uk-spinner></span>
    <div 
      class="uk-grid-small uk-width-1-1 uk-grid-match"
      uk-grid
      id="list-media-items"
      v-if="!spinner && view==='tile' && sort==='Type'"
    >
     <DraggableItem
        v-for="(item, i) in mediaData" 
        :key="i"
        :type="item.__typename"
        v-model="item.id"
        class="uk-grid-item-match uk-grid-margin"
        @drop="drop( $event, item )"
      >
        <MediaFolderCard v-if="item.__typename==='MediaFolder'" :folder="item" @refetch="refetchData"/>
        <MediaFileCard v-else :id="i" :is-modal="isModal" :item="item" @refetch="refetchData"/>
      </DraggableItem>
    </div>
    <div 
      class="uk-grid-small uk-width-1-1 uk-grid-match"
      uk-grid
      id="list-media-items"
      v-if="!spinner && view==='tile' && sort!=='Type'"
    >
    <DraggableItem 
        v-for="(folder, i) in folders" 
        :key="folder+i"
        :type="folder.__typename"
        v-model="folder.id"
        class="uk-grid-item-match uk-grid-margin"
        @drop="drop( $event, folder )"
      >
      <MediaFolderCard v-if="sort!=='Type'" :folder="folder" @refetch="refetchData"/>
    </DraggableItem>
    <DraggableItem 
        v-for="(item, i) in items" 
        :key="i"
        :type="item.__typename"
        v-model="item.id"
        class="uk-grid-item-match uk-grid-margin"
        @drop="drop( $event, item )"
      >
      <MediaFileCard :id="i" :is-modal="isModal" :item="item" @refetch="refetchData"/>
    </DraggableItem>
    </div>
    <div v-if="!spinner && !isDataAvailable && view==='table'">
      <BaseTable removeBorderBottom :tableHeaders="tableHeaders" class="uk-margin-small-top">
        <MediaFolderCard class="uk-margin-remove" v-for="(folder, i) in folders" :key="folder+i" :view="view" :folder="folder" @refetch="refetchData"/>
        <MediaFileCard class="uk-margin-remove" v-for="(item, i) in items" :key="i" :view="view" :is-modal="isModal" :item="item" @refetch="refetchData"/>
      </BaseTable>
    </div>
    <div v-if="isDataAvailable && !spinner">
      <span class="uk-text-meta"> {{$t('Pages.MediaLibrary.NoData')}} </span>
      <a class="uk-text-primary " @click="openFolderModal">{{$t('Pages.MediaLibrary.NewFolder')}}</a>
      <span class="uk-text-meta"> or </span>
      <a class="uk-text-primary " href="#upload-modal" uk-toggle>{{$t('Actions.Uploading')}}</a>
    </div>
    <span class="uk-margin-small-top" v-if="loadMoreSpinner && !spinner" uk-spinner></span>
    <upload-modal id="upload-modal" :media-class="mediaClassFilter !== 'All' ? mediaClassFilter : ''"/>
    <div container="media-library" id="new-folder-modal" uk-modal="bg-close: false; esc-close: false">
      <div class="uk-modal-dialog">
        <h3 class="uk-modal-header uk-text-left uk-margin-remove-bottom"> {{ editFolder ? $t('Pages.MediaLibrary.EditFolderName') : $t('Pages.MediaLibrary.CreateNewFolder') }} </h3>
        <div class="uk-modal-body">
          <p class="uk-text-left uk-margin-remove-bottom"> {{$t('Pages.MediaLibrary.FolderName') }} <span class="uk-text-danger">*</span> </p>
          <div class="uk-inline uk-width-1-1">
            <input class="uk-input" :class="{ 'invalid-border': errors.folderName }" type="text" v-model.trim="name" :placeholder="$t('Generic.Labels.Name')" @keyup="validateFolderName()"/>
            <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.folderName" uk-icon="icon: warning" ></span>
            <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.folderName">{{errors.folderName}}</span>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-margin-small-right uk-modal-close" type="button" @click="cancelFolderCreation"> {{ $t('Actions.Cancel') }} </button>
          <button v-if="!editFolder && !loading" class="uk-button uk-button-primary uk-margin-small-right" type="button" @click.prevent="createNewFolder" > {{ $t('Actions.Create') }}  </button>
          <button v-if="editFolder && !loading" class="uk-button uk-button-primary uk-margin-small-right" type="button"  @click.prevent="updateMediaFolderName" > {{ $t('Actions.Update') }}  </button>
          <button v-else-if="loading" class="uk-button uk-button-primary uk-margin-small-right" type="button" :disabled="true"  > <span uk-spinner="ratio: 1"> </span> </button>        
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { MediaClass } from '../../utils/enums';
import { sortBy, add } from '../../utils/mediaItem';
import MediaFileCard from '@/components/cards/MediaFileCard.vue';
import MediaFolderCard from '@/components/cards/MediaFolderCard.vue';
import UploadModal from '@/components/modals/UploadModal.vue';
import Notification from '@/components/util/Notification';
import BaseTable from '@/components/tables/BaseTable.vue';
import DraggableItem from '@/components/draggable/DraggableItem.vue';

const getQueryMediaTypeFilter = selectedMediaClassFilter => (
    selectedMediaClassFilter !== 'All'
        ? [ selectedMediaClassFilter === MediaClass.VIDEO || selectedMediaClassFilter === MediaClass.LIVE ? 'MediaBundle' : 'Media' ]
        : [ 'MediaBundle', 'Media' ]);

const supportedMediaClasses = MediaClass.values
    .filter( value => value !== MediaClass.VIDEO );

const getQueryMediaClassFilter = selectedMediaClassFilter => (
    selectedMediaClassFilter
        ? supportedMediaClasses.filter( mediaClass => mediaClass === selectedMediaClassFilter )
        : supportedMediaClasses );

export default {
  name: 'MediaLibraryView',
  components: {
    MediaFileCard,
    MediaFolderCard,
    UploadModal,
    BaseTable,
    DraggableItem
  },
  props: {
    path: { type: String, default: '' },
    isModal: { type: Boolean, default: false },
    mode: { type: String, default: 'tile' }
  },
  data () {
    return {
      MediaClass,
      timer: {},
      filter: false,
      type: ["MediaBundle", "Media"],
      spinner:  false,
      loadMoreSpinner: false,
      mediaClassFilter: this.$route?.query?.mediaClass || 'All',
      fetching: false,
      folder: null,
      name: '',
      searchInput: this.$route?.query?.searchText || '',
      sort: 'Date',
      sortDirection: 'desc',
      editFolder: false,
      loading: false,
      errors: {
        folderName: false
      },
      orgId: this.$route.params.id,
      forceReload: false,
      tableHeaders: [{
        name: '',
        class: 'uk-text-center uk-width-small'
      }, {
        name: this.$t('Pages.MediaLibrary.Headers.Name'),
        class: 'uk-width-medium' 
      }, {
        name: this.$t('Pages.MediaLibrary.Headers.Type'),
        class: 'uk-width-medium uk-text-center'
      }, {
        name: this.$t('Pages.MediaLibrary.Headers.Badges'),
        class: 'uk-width-small uk-text-center'
      }, {
        name: this.$t('Pages.MediaLibrary.Headers.Size'),
        class: 'uk-width-small uk-text-center'
      }, {
        name: this.$t('Pages.MediaLibrary.Headers.CreatedDate'),
        class: 'uk-width-small uk-text-center'
      },
      {
        name: this.$t('Pages.MediaLibrary.Headers.Actions'),
        class: 'uk-width-small uk-text-center'
      }],
      sortMap : {
        asc: 'ASC',
        desc: 'DESC',
        Name: 'title',
        Date: 'createdAt',
        Type: 'mediaClass',
        Size: 'size'
      }
    }
  },
  computed: {
    ...mapState({
      mediaFolders: state => state.venom.org.mediaFolders,
      mediaItems: state => state.venom.org.mediaLibraryItems?.filter( item => (item.node.__typename === 'MediaBundle' && item.node.masterVideo) || item.node.__typename === 'Media' ),
      mediaLibraryCursor: state => state.venom.org.mediaLibraryCursor,
      hasNextPage: state => state.venom.org.hasNextPage,
      view: state => state.venom.org.mediaLibraryView || 'tile',
      orgName: state => state.venom.org.name,
      currentFolderName: state => state.venom.org.currentFolderAndParents?.folder?.name,
      currentFolder: state => state.venom.org.currentFolderAndParents?.folder,
      parent: state => state.venom.org.currentFolderAndParents?.parents
    }),
    mediaData() {
      return this.sortMedia([ ...this.mediaFolders, ...this.mediaItems?.map( e => e.node )]) || [];
    },
    folders () {
      return this.sortMedia(this.mediaFolders) || [];
    },
    items () {
      return this.sortMedia(this.mediaItems?.map( e => e.node )) || [];
    },
    back () {
      const parts = this.path.split('/');
      parts.pop();
      const parentPath = parts.length >= 1 ? parts.join( '/' ) : undefined;
      return {
          name: 'MediaLibrary',
          params: { id: this.orgId, path: parentPath },
          query: { mediaClass: this.$route.query?.mediaClass, searchText: this.$route.query?.searchText }
      };
    },
    isDataAvailable () {
      return this.mediaFolders?.length === 0 && this.mediaItems?.length === 0;
    },
    folderName () {
      if (this.currentFolder?.isRoot) return this.$t('Actions.Loading')
      return this.path ? this.currentFolderName : false;
    }
  },
  watch: {
    '$route.params.path':  {
      handler () {
        this.fetchMediaLibraryItems();
      }
    }
  },
  mounted() {
    if(!this.$store.state.venom.org.mediaFolders)
      this.spinner = true;
    this.fetchMediaLibraryItems();
    document.documentElement.scrollTop = 0
    this.infiniteScroll();
  },
  methods: {
  async drop ( draggableMedia, moveToId ) {
    const { id, type } = draggableMedia;
    if( id !== moveToId.id && moveToId.__typename === 'MediaFolder') {
      if( type === 'MediaFolder') {
        await this.$store.dispatch('updateMediaFolder',{ folderId: id, moveTo: moveToId.id })
        this.forceReload = true;
      } else if ( type === 'MediaBundle' ) {
        await this.$store.dispatch('updateMediaBundle',{ variables: { folder: id, moveTo: moveToId.id }})
      } else {
        await this.$store.dispatch('updateMedia', { variables: { media: id, moveToFolder: moveToId.id }})
      }
      this.fetchMediaLibraryItems();
    }
  },
  validateFolderName () {
    if (this.editFolder) {
      if (!this.name || this.name.length < 2 || !this.name.trim()) {
        return this.errors.folderName = this.$t('Generic.Errors.Min2Chars')
      } else {
        return this.errors.folderName = false
      }
    } else {
      if (!this.name || this.name.length < 2 || !this.name.trim()) {
        return this.errors.folderName = this.$t('Generic.Errors.Min2Chars')
      } else if (this.name.length >= 2) {
        return this.errors.folderName = false
      }
    }
  },
  sortData ( type ) {
    this.sort = type;
    this.sortDirection === 'desc' ? this.sortDirection = 'asc' : this.sortDirection = 'desc';
    this.fetchMediaLibraryItems()
  },
  isMediaBundle ( media ) { return media.__typename === 'MediaBundle'; },
  sortMedia ( data ) {
     if ( this.sort === 'Type' ) {
          data.forEach( folder => { if(folder.__typename==='MediaFolder')  folder.mediaClass = 'FOLDER'; });
      }
      data?.forEach( media => {
          if ( media.__typename === 'Media' )
              media.name = media.title;
          if ( this.isMediaBundle( media ) ) {
              media.mediaClass = media.masterVideo ? media.masterVideo.mediaClass : 'VIDEO';
              media.size = media.masterVideo ? media.masterVideo.size : 0;
              media.createdAt = media.masterVideo ? media.masterVideo.createdAt : '';
          }
          if ( media.__typename === 'MediaFolder' ) {
              media.size = this.folderSize( media ).size;
          }
      });
      switch ( this.sort ) {
      case 'Name':
          return sortBy( data, 'name', this.sortDirection );

      case 'Date':
          return sortBy( data, 'createdAt', this.sortDirection );

      case 'Type':
          return sortBy( data, 'mediaClass', this.sortDirection );

      case 'Size':
          return sortBy( data, [ v => v.size.bits ], this.sortDirection );

      default:
          return sortBy( data, 'createdAt', this.sortDirection );
      }
    },
    folderSize ( media ) {
      media.size = { bits: 0 };
      const items = media.items?.edges || media.interfaceItems?.edges || [];
      const nodes = items.map( e => e.node );
      if ( nodes.length > 0 ) {
          nodes.forEach( node => {
              if ( this.isMediaBundle( node ) ) {
                  node.size = node.masterVideo?.size?.bits;
              }

              node.size = node.size !== undefined ? ( node.size?.bits || node.size ) : 0;
          });
          media.size.bits = add( nodes, 'size' );
          return media;
      }
      return media;
    },
    async fetchMediaLibraryItems() {
      if(!this.searchInput) {
        if(this.$store.state.venom.org.mediaLibraryRootFolderID !== this.$store.state.venom.org.currentFolderAndParents?.folder?.id || this.$route.params.path || this.forceReload )
          this.spinner = true;
        let orgid = this.$route.params.id;
        const filter = this.mediaClassFilter !== 'All' ? true : false;
        const type = filter ? getQueryMediaTypeFilter(this.mediaClassFilter) : this.type;
        if( !this.$route.params.path) {
          this.folder = await this.$store.dispatch('fetchMediaLibraryItems', { orgId: orgid, type: type, filter: filter, mediaClass: this.mediaClassFilter !== 'All' ? this.mediaClassFilter : null});
        } else {
          const id = this.path.split( '/' ).pop();
          this.folder = await this.$store.dispatch('fetchMediaLibraryFolderItems',{ orgId: orgid,  type: type, folderId: id, filter: filter, mediaClass: this.mediaClassFilter !== 'All' ? this.mediaClassFilter : null })  
        }
        this.forceReload = true;
        this.spinner = false;
      } else {
        this.changed();
      }
    },
    infiniteScroll() {
      window.onscroll = () => {
        let bottomOfWindow = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;

        if (bottomOfWindow && !this.fetching && this.hasNextPage ) {
          this.fetching = true;
          this.loadMore();
        }
      }
    },
    async loadMore () {
      this.loadMoreSpinner = true;
      if( !this.$route.params.path ) {
        const filter = this.mediaClassFilter !== 'All' ? true : false;
        const type = filter ? getQueryMediaTypeFilter(this.mediaClassFilter) : this.type;
        await this.$store.dispatch('fetchMediaLibraryItems', { orgId: this.orgId, mediaClass: this.mediaClassFilter !== 'All' ? this.mediaClassFilter : null, type: type, filter: filter, after: this.mediaLibraryCursor });
      } else if ( !this.spinner ) {
        const id = this.path.split( '/' ).pop();
        await this.$store.dispatch('fetchMediaLibraryFolderItems',{ orgId: this.orgId, mediaClass: this.mediaClassFilter !== 'All' ? this.mediaClassFilter : null,type: this.type, folderId: id,  after: this.mediaLibraryCursor, filter: this.filter  })  
      }
      this.loadMoreSpinner = false;
      this.fetching = false;
    },
    async filterMediaItems ( mediaClass ) {
      this.mediaClassFilter = mediaClass;
      this.type = getQueryMediaTypeFilter(mediaClass);
      if( !this.searchInput ) {
        this.spinner = true;
        if ( mediaClass === 'All' ) {
          this.filter = false;
          const url = new URL(window.location);
          url.searchParams.set('mediaClass', '');
          window.history.replaceState({}, '', url);
          if( !this.$route.params.path) {
            await this.$store.dispatch('fetchMediaLibraryItems', {  orgId: this.orgId, type: this.type, filter: this.filter });
          } else {
            const id = this.path.split( '/' ).pop();
            await this.$store.dispatch('fetchMediaLibraryFolderItems',{  orgId: this.orgId,  type: this.type, folderId: id, filter: this.filter })  
          }
        } else {
          this.filter = true;
          const url = new URL(window.location);
          url.searchParams.set('mediaClass', this.mediaClassFilter);
          window.history.replaceState({}, '', url);
          if( !this.$route.params.path) {
            await this.$store.dispatch('fetchMediaLibraryItems', {  orgId: this.orgId, mediaClass: this.mediaClassFilter !== 'All' ? this.mediaClassFilter : null, type: this.type, filter: this.filter });
          } else {
            const id = this.path.split( '/' ).pop();
            await this.$store.dispatch('fetchMediaLibraryFolderItems',{ orgId: this.orgId, mediaClass: this.mediaClassFilter !== 'All' ? this.mediaClassFilter : null,type: this.type, folderId: id, filter: this.filter  })  
          }
        }
        this.spinner = false;
      } else {
        this.changed();
      } 
    },
    async refetchData () {
      this.spinner = true;
      if( this.$route.params.path ) {
        const parts = this.$route.params.path.split( '/' );
        const folderId = parts.pop();
        await this.$store.dispatch('fetchMediaLibraryFolderItems',{ orgId: this.orgId,  type: this.type, folderId: folderId, filter: false })  }
      else {
        await this.$store.dispatch('fetchMediaLibraryItems', {  orgId: this.orgId, type: this.type, filter: false});
      }
      this.spinner = false;
    },
    async refreshMediaPicker () {
        await this.$store.dispatch('fetchMediaLibraryItems', {  orgId: this.orgId, type: this.type, filter: false, modal: true});
    },
    async createNewFolder () {
      if (!this.name) {
        this.errors.folderName = this.$t('Generic.Errors.Min2Chars')
        return
      }
      if(!this.errors.folderName) {
        this.loading = true;
        const id = await this.$store.dispatch('createFolder', { name: this.name, parent: this.folder.id });
        this.loading = false;
        if(id) {
          Notification.showNotification(this.$t( 'Actions.Created'), this.$t( 'Pages.MediaLibrary.FolderCreated', { name: this.name }));
          this.$router.push(`${this.$route.path}/${id}`); 
          window.UIkit.modal('#new-folder-modal').hide();
          this.name = '';
        }
      }
    },
    openFolderModal () {
      window.UIkit.modal('#new-folder-modal').show();
    },
    cancelFolderCreation () {
      this.name = '';
      this.errors.folderName = false;
      this.editFolder = false;
    },
    changeDisplay (type) {
      this.$store.commit('setMediaLibraryView', type );
    },
    processChange () {
      this.debounceSearch(300)
    },
    async changed () {
      if(this.searchInput) {
        if( !this.$route.params.path) {
          await this.$store.dispatch('getRootFolderSearchItems', { orgId: this.orgId, type: this.type, searchInput: this.searchInput, mediaClasses: getQueryMediaClassFilter(this.mediaClassFilter !== 'All' ? this.mediaClassFilter : '') });
        } else {
          const id = this.path.split( '/' ).pop();
          await this.$store.dispatch('getFolderSearchItems',{ orgId: this.orgId, type: this.type, folderId: id, searchInput: this.searchInput, mediaClasses: getQueryMediaClassFilter(this.mediaClassFilter !== 'All' ? this.mediaClassFilter : '') })  
        }
      } else {
        this.fetchMediaLibraryItems();
      }
    },
    onBlur () {
      const url = new URL(window.location);
      url.searchParams.set('searchText', this.searchInput);
      window.history.replaceState({}, '', url);
    },
    clearSearch () {
      this.searchInput = '';
      const url = new URL(window.location);
      url.searchParams.set('searchText', this.searchInput);
      window.history.replaceState({}, '', url);
      this.fetchMediaLibraryItems();
      this.$refs.searchInput.focus();
    },
    editFolderName () {
      this.editFolder = true;
      window.UIkit.modal('#new-folder-modal').show();
      this.name = this.currentFolderName;
    },
    async updateMediaFolderName () {
      if (!this.name || this.name.trim().length < 2 ) {
        this.errors.folderName = this.$t('Generic.Errors.Min2Chars')
        return
      }
      this.loading = true;
      const response = await this.$store.dispatch('editMediaFolderName', { folderId: this.folder.id, name: this.name });
      if(response) {
        this.editFolder = false;
        this.name = '';
        this.currentFolder.name = response.updateMediaFolder.change.current.name;
        this.$store.commit( 'setCurrentFolderNameAndParents',{ folder: this.currentFolder, parents: this.parent } );
        this.loading = false;
        window.UIkit.modal('#new-folder-modal').hide();
        Notification.showNotification(this.$t( 'Actions.Updated'), this.$t( 'Pages.MediaLibrary.FolderUpdated', { name: this.name }));
      }
    },
    debounceSearch (timeout = 300) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => { this.changed() }, timeout) 
    }
  }
}
</script>

<style lang="scss" scoped>
  .name {
    cursor: pointer;
  }

  .new-folder-btn {
    padding: 0 20px;
  }
  #folder-edit-icon:hover {
    color: var(--text-color)!important;
  }
</style>