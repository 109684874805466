<template>
  <RouterLink v-if="view === 'tile'" :tag="isModal ? 'div' : 'a'" class="uk-card uk-card-default uk-width-small uk-height-small uk-text-center uk-card-hover uk-border-rounded" style="cursor: pointer; text-decoration: none;" :event="isModal ? '' : 'click'" :to="isModal ? '' : folder.id" :append="!isModal" @click.native="isModal && $emit( 'click' )">
      <div class="uk-margin-small-right uk-margin-small-left">
      <span v-if="!isModal" class="uk-align-right uk-margin-remove" style="cursor: pointer" uk-icon="icon: more" />
      <div v-if="!isModal" style="min-width: 100px;" :id="'delete-dropdown-'+folder.id" class="uk-padding-remove" uk-toggle uk-dropdown="mode: click; pos: bottom-right; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 0">
        <ul class="uk-nav uk-dropdown-nav" style="margin-top: 5px; margin-bottom: 5px">
          <li class="uk-text-left uk-margin-small-left uk-text-default"> <span @click.prevent="onClick" class="uk-flex uk-flex-middle"> <span uk-icon="icon: folder; ratio:0.8" /> <span style="margin-left: 5px">
            {{$t('Actions.Move')}}</span> </span> </li>
          <li class="uk-text-left uk-margin-small-left uk-text-default"> <span @click.prevent="deleteFolder" class="uk-flex uk-flex-middle"> <span  uk-icon="icon: trash; ratio:0.8" /> <span style="margin-left: 5px">{{$t('Actions.Delete')}}</span> </span> </li>
        </ul>
      </div>
      <span class="folder uk-margin-top" :class="{ 'uk-margin-left': !isModal }" uk-icon="icon: folder; ratio: 3"></span>
      <div>
        <p class="uk-text-truncate uk-card-title title-font" :uk-tooltip="folder.name"> {{ folder.name }} <br />
          <span v-if="!isModal" class="uk-text-meta meta-data"> {{ childCount
                ? ( childCount === 1 ? $t( 'tileMediaFolder.Item'):$t( 'tileMediaFolder.nItems', { n: childCount }))
                : $t( 'tileMediaFolder.empty' ) }} </span> <br />
          <span v-if="!isModal" class="uk-text-meta meta-data"> {{ folder.createdAt | date }} </span>
        </p>
      </div>
    </div>
    <div id="info-modal-media-folder" uk-modal="bg-close: false; esc-close: false">
      <div class="uk-modal-dialog uk-modal-body">
          <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
          <p class="uk-text-meta">{{ $t('DeleteModal.CannotDeleteFolder') }}</p>
      </div>
    </div>
        <div v-if="show" :id="'media-picker-modal-'+folder.id" uk-modal="bg-close: false; esc-close: false">
        <div class="uk-modal-dialog uk-modal-body uk-width-3-4 media-picker-modal">
          <ModalMediaPicker mode="folder" :itemsDisabled="true" :submit="submit" @select="moveToFolder" :parent-id="folder.parent.id" :move-folder-id="folder.id" @cancel="closeModal" />
        </div>
    </div>
  </RouterLink>
  <RouterLink v-else :tag="isModal ? 'div' : 'tr'" class="uk-items-center" style="cursor: pointer; text-decoration: none;" :event="isModal ? '' : 'click'" :to="isModal ? '' : folder.id" :append="!isModal" @click.native="isModal && $emit( 'click' )"> 
      <td class="uk-text-center">
        <div uk-icon="icon: folder; ratio: 2.2" />
      </td>
      <td>
        <p class="uk-text-truncate uk-width-large"> {{ folder.name }} </p>
      </td>
      <td class="uk-text-center">
        {{ $t( 'Pages.MediaLibrary.Folder') }}
      </td>
      <td class="uk-text-center">
       <div uk-icon="icon: folder; ratio: 1" />
      </td>
      <td class="uk-text-center">
        <span> {{ childCount
            ? $t( 'tileMediaFolder.nItems', { n: childCount })
            : $t( 'tileMediaFolder.empty' ) }} </span>
      </td>
      <td class="uk-width-small uk-text-center">
        <span> {{ folder.createdAt | date }} </span>
      </td>
      <td class="uk-flex-center uk-flex uk-margin-small-top">
        <div :uk-tooltip="$t('Actions.Move')" @click.stop="onClick" class="uk-margin-small-right"  uk-icon="icon: move; ratio:1"/>
        <div :uk-tooltip="$t('Actions.Delete')" @click.stop="deleteFolder"> <span  uk-icon="icon: trash; ratio:0.9" /></div>
      </td> 
      <div id="info-modal" uk-modal="bg-close: false; esc-close: false">
      <div class="uk-modal-dialog uk-modal-body">
          <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
          <p class="uk-text-meta">{{ $t('DeleteModal.CannotDeleteMedia') }}</p>
      </div>
    </div>
    <div id="info-modal-media-folder" uk-modal="bg-close: false; esc-close: false">
      <div class="uk-modal-dialog uk-modal-body">
          <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
          <p class="uk-text-meta">{{ $t('DeleteModal.CannotDeleteFolder') }}</p>
      </div>
    </div>
    <div v-if="show" :id="'media-picker-modal-'+folder.id" uk-modal="bg-close: false; esc-close: false">
        <div class="uk-modal-dialog uk-modal-body uk-width-3-4 media-picker-modal">
          <ModalMediaPicker mode="folder" :itemsDisabled="true" :submit="submit" @select="moveToFolder" :parent-id="folder.parent.id" :move-folder-id="folder.id" @cancel="closeModal" />
        </div>
    </div>
  </RouterLink>
</template>

<script>
import { mapGetters } from 'vuex';
import Notification from '@/components/util/Notification';

export default {
    name: 'MediaFolderCard',
    components: {
      ModalMediaPicker: () => import('@/components/modals/ModalMediaPicker.vue')
    },
    props: {
        folder: {
            type: Object,
            required: true
        },
        isModal: {
          type: Boolean,
          default: false
        },
        view: {
          type: String,
          default: 'tile'
        }
    },
    data () {
    return {
      show: false,
      submit: false
    }
  },
    computed: {
      ...mapGetters([
        'orgId'
        ]),
        childCount () {
          return this.folder.interfaceItems?.totalCount || 0;
        },
        folderPath () {
          return {
            name: "MediaLibrary",
            params: { path: this.folder.id }
          }
        }
    },
    methods: {
      deleteFolder () {
        const id = this.folder.id;
        if ( this.view === 'tile') {
          setTimeout( function() {
              window.UIkit.dropdown(`#delete-dropdown-${id}`).hide();
          }, 1000 );
        }
        if(this.childCount > 0 ){
          window.UIkit.modal('#info-modal-media-folder').show();
        } else {
          window.UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDeleteMedia', { name: this.folder.name })).then( async () => {
              const response = await this.$store.dispatch('deleteMediaFolder', { folderId: this.folder.id });
                if ( !response.errors ) {
                  this.$emit('refetch');
                  Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: response.deleteMediaFolder.folder.name }));
                } else {
                    Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), response?.errors[0]?.message,'error');
                }
              });
        }
      },
      openFolder () {
        const computedPath = this.$route.params.path !== undefined ? this.$route.params.path + `/${this.folder.id}` : this.folder.id;
        this.$router.push({ name: 'MediaLibrary', params: { path: computedPath, id: this.orgId } })
      },
       onClick () {
        this.show = true;
        const id = this.folder.id;
            setTimeout( function() {
              window.UIkit.modal(`#media-picker-modal-${id}`).show();
        }, 1000 );
      },
      closeModal () {
        const id = this.folder.id;
        window.UIkit.modal(`#media-picker-modal-${id}`).hide();
        setTimeout( function() {
          this.show = false;
          }, 100 );
      },
      async moveToFolder ( selectedFolder ) {
        this.submit = true;
        const response = await this.$store.dispatch('updateMediaFolder',{ folderId: this.folder.id, moveTo: selectedFolder })
        this.submit = false;
        if( response ) {
          Notification.showNotification(this.$t( 'DeleteModal.Moved'), this.$t( 'DeleteModal.HasBeenMoved', { name: this.folder.name }));
          this.closeModal();
          this.$emit('refetch');
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.uk-width-small {
  width: 215px;
}

.uk-height-small {
    height: 160px;
}

.folder {
  color: #cacaca;
}

.trash-icon {
  width: 20px;
  height: 20px;
}

.more-options {
  padding: 5px 0px;
}

.title-font {
  font-size: 14px;
}

.meta-data {
  font-size: 10px;
}
</style>